export class MessageValidator {
  private static endingPatterns = [
    "summary of our discussion",
    "summary of our conversation",
    "review this summary",
    "i'll send this to you via email",
    "have a great day",
    "ready to close",
    "have a wonderful day",
    "thank you for your time",
    "best of luck",
    "pleasure helping you",
    "let me know if you need anything else"
  ];

  static hasEndingPattern(message: string): boolean {
    const lowerMessage = message.toLowerCase().trim();
    
    const hasSummaryFormat = (
      lowerMessage.includes("to summarize:") || 
      lowerMessage.includes("in summary:") ||
      lowerMessage.includes("does this accurately capture")
    );
    
    const isLikelyEnding = 
      !lowerMessage.includes("let's") &&
      !lowerMessage.includes("could you") &&
      !lowerMessage.includes("tell me");
    
    return isLikelyEnding && (
      hasSummaryFormat || 
      this.endingPatterns.some(pattern => 
        lowerMessage.includes(pattern.toLowerCase())
      )
    );
  }


  static hasSummaryKey(message: string): boolean {
    const trimmedContent = message.trim();
  
    // Regex for JSON block format (```json { "isSummary": true } ```)
    const jsonBlockRegex = /```json[\s\S]*?"isSummary"\s*:\s*true[\s\S]*?```/i;
  
    // Regex for simple inline JSON format ({ "isSummary": true })
    const jsonInlineRegex = /{\s*["']isSummary["']\s*:\s*true\s*}/i;
  
    // Regex for plain-text summary format (isSummary true)
    const plainTextRegex = /isSummary\s*[:=]?\s*true/i;
  
    // Check for any of the formats
    if (jsonBlockRegex.test(trimmedContent) || jsonInlineRegex.test(trimmedContent) || plainTextRegex.test(trimmedContent)) {
      return true;
    }
  
    return false;
  }

  static shouldShowEndChat(messages: Array<{ role: string; content: string }>): boolean {
    // Only check the last few messages (e.g., last 3)
    const recentMessages = messages.slice(-3);
    
    return recentMessages.some(message => 
      this.hasSummaryKey(message.content)  
      //this.hasEndingPattern(message.content)
    );
  }
} 