import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import robotIcon from '../../assets/robot_icon.png';
import logoIcon from '../../assets/monstarx_logo_w.png';
import '../../styles/style.css';
import { fetchSessionHistory, searchSessions } from '../../api/sessionHistoryApi';
import ChatHistory from '../../components/ChatHistory';
import { checkAuthToken, clearSession } from '../../utils/auth';

interface Session {
  sessionToken: string;
  messages: any[];
  lastUpdated: string;
  userDetails: {
    userAgent?: string;
  };
  name?: string;
  email?: string;
  chatQuality?: number;
}

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [nextPageToken, setNextPageToken] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [selectedSession, setSelectedSession] = useState<Session | null>(null);

  const loadSessions = async (lastDoc?: string) => {
    try {
      if (lastDoc) {
        setLoadingMore(true);
      } else {
        setLoading(true);
      }
      
      const response = await fetchSessionHistory(50, lastDoc);
      console.log('Response data:', response.data.sessions);
      
      if (response.status === 'success') {
        const formattedSessions: Session[] = response.data.sessions.map((session: any) => {
          console.log('Individual session:', session);
          return {
            ...session,
            userDetails: {
              ...session.userDetails,
              name: session.name || undefined,
              email: session.email || undefined,
              chatQuality: session.chatQuality || undefined
            }
          };
        });
        
        setSessions(prev => lastDoc ? [...prev, ...formattedSessions] : formattedSessions);
        setHasMore(response.data.hasMore);
        setNextPageToken(response.data.nextPageToken);
      }
    } catch (error) {
      console.error('Error loading sessions:', error);
    } finally {
      setLoadingMore(false);
      setLoading(false);
    }
  };

  const handleSearch = async (term: string) => {
    try {
      setLoading(true);
      setSearchTerm(term);
      
      if (term.trim()) {
        const response = await searchSessions(term);
        if (response.status === 'success') {
          const formattedSessions: Session[] = response.data.sessions.map((session: any) => ({
            ...session,
            userDetails: {
              ...session.userDetails,
              name: session.userDetails?.name || undefined,
              email: session.userDetails?.email || undefined,
              chatQuality: session.userDetails?.chatQuality || undefined
            }
          }));
          setSessions(formattedSessions);
          setHasMore(response.data.hasMore);
          setNextPageToken(response.data.nextPageToken);
        }
      } else {
        loadSessions();
      }
    } catch (error) {
      console.error('Error searching sessions:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!checkAuthToken()) {
      navigate('/panel-bot/login');
    } else {
      loadSessions();
    }
  }, [navigate]);

  const handleLogout = () => {
    clearSession();
    navigate('/panel-bot/login');
  };

  const formatDateTime = (dateString: string) => {
    return new Date(dateString).toLocaleString();
  };

  const truncateUserAgent = (userAgent: string) => {
    return userAgent.length > 50 ? `${userAgent.substring(0, 50)}...` : userAgent;
  };

  const getQualityColor = (quality: number): string => {
    if (quality < 10) return '#ffcdd2';  // Light Red
    if (quality <= 30) return '#bbdefb'; // Light Blue
    if (quality <= 50) return '#ffe0b2'; // Light Orange
    if (quality <= 70) return '#f8bbd0'; // Pink
    return '#c8e6c9';                    // Green
  };

  const QualityIndicator: React.FC<{ quality: number }> = ({ quality }) => {
    return (
      <div 
        style={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: '8px' 
        }}
      >
        <div
          style={{
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            backgroundColor: getQualityColor(quality),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span role="img" aria-label="quality">⭐</span>
        </div>
        <span>{quality}%</span>
      </div>
    );
  };

  const filteredSessions = sessions.filter(session => {
    const searchTermLower = searchTerm.toLowerCase();
    
    // Search in session token
    if (session.sessionToken.toLowerCase().includes(searchTermLower)) {
      return true;
    }

    // Search in messages content (excluding the first system message)
    const hasMatchingMessage = session.messages.slice(1).some(message => 
      message.content && message.content.toLowerCase().includes(searchTermLower)
    );

    return hasMatchingMessage;
  });

  const handleViewHistory = (session: Session) => {
    setSelectedSession(session);
  };

  if (loading) {
    return (
      <div className="main-container">
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard-page">
      <header className="chat-header">
        <div className="header-left">
          <div className="header-robot-icon">
            <img src={robotIcon} alt="Robot" className="robot-icon" />
          </div>
          <img src={logoIcon} alt="Logo" className="chat-logo" />
        </div>
        <button className="admin-btn" onClick={handleLogout}>
          Logout
        </button>
      </header>

      <div className="dashboard-content">
        <div className="search-container">
          <input
            type="text"
            placeholder="Search by session or keywords in chat..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
        </div>

        <div className="sessions-table">
          <table>
            <thead>
              <tr>
                <th>Session</th>
                <th>Date & Time</th>
                <th>Name</th>
                <th>Email</th>
                <th>Chat Quality</th>
                <th>Chat History</th>
              </tr>
            </thead>
            <tbody>
              {filteredSessions.map((session) => (
                <tr key={session.sessionToken}>
                  <td>{session.sessionToken}</td>
                  <td>{formatDateTime(session.lastUpdated)}</td>
                  <td>{session.name || 'Not Found'}</td>
                  <td>{session.email || 'Not Found'}</td>
                  <td>
                    {session.chatQuality ? (
                      <QualityIndicator quality={session.chatQuality} />
                    ) : (
                      'Not Found'
                    )}
                  </td>
                  <td>
                    <button 
                      className="view-history-btn"
                      onClick={() => handleViewHistory(session)}
                    >
                      View History ({session.messages.length})
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {hasMore && (
          <div className="load-more">
            <button 
              className="admin-btn"
              onClick={() => loadSessions(nextPageToken)}
              disabled={loadingMore}
            >
              {loadingMore ? 'Loading...' : 'Load More'}
            </button>
          </div>
        )}
      </div>

      {selectedSession && (
        <ChatHistory 
          session={selectedSession}
          onClose={() => setSelectedSession(null)}
        />
      )}
    </div>
  );
};

export default Dashboard;