import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

interface ReportStatus {
  reportStatus: number | null;
  message: string;
  failedStep?: number;
  error?: string;
  step1?: string;
  step2?: string;
  step3?: string;
  step4?: string;
  name?: string;
  email?: string;
  timestamp?: string;
}

interface ApiResponse {
  status: string;
  message: string;
  data: ReportStatus | null;
}

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': API_KEY,
  },
  withCredentials: true,
});

export class SRSReportApi {
  static async getReportStatus(sessionToken: string): Promise<ApiResponse> {
    try {
      const response = await api.get(`/api/srs-report/status`, {
        params: { session_token: sessionToken }
      });
      return response.data;
    } catch (error: any) {
      console.error('Error fetching report status:', error.response?.data || error.message);
      throw error;
    }
  }

  static async generateReport(sessionToken: string): Promise<ApiResponse> {
    try {
      const response = await api.post('/api/srs-report', {
        session_token: sessionToken
      });
      return response.data;
    } catch (error: any) {
      console.error('Error generating report:', error.response?.data || error.message);
      throw error;
    }
  }
} 