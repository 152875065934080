// src/hooks/useChat.ts
import { useState, useCallback } from 'react';
import { ChatMessage } from '../models/ChatMessage';
import { createSession, sendMessage, checkSessionHistory } from '../api/chatApi';
import { MessageValidator } from '../utils/messageValidator';

export const useChat = (setNotification: (message: string) => void) => {
  const [sessionToken, setSessionToken] = useState<string | null>(() => 
    localStorage.getItem('monstarx_session_token')
  );
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [isTyping, setIsTyping] = useState(false);
  const [showEndChatOptions, setShowEndChatOptions] = useState(false);

  const initializeSession = useCallback(async () => {
    try {
      const continuingFromIntro = localStorage.getItem('continuing_from_intro');
      const continuePrevious = localStorage.getItem('continue_previous_session');
      const existingToken = localStorage.getItem('monstarx_session_token');

      if ((continuingFromIntro || continuePrevious) && existingToken) {
       // console.log('useChat: Loading existing session history');
        const history = await checkSessionHistory(existingToken);
        setSessionToken(existingToken);
        setMessages(history.data.messages.map((msg: { role: string; content: string }) => ({
          role: msg.role === 'You' ? 'user' : 'assistant',
          content: msg.content
        })));
      } else {
       // console.log('useChat: Creating new session');
        const response = await createSession();
        localStorage.setItem('monstarx_session_token', response.session_token);
        setSessionToken(response.session_token);
        setMessages([{
          role: 'assistant',
          content: response.data.content
        }]);
      }
    } catch (error) {
      console.error('useChat: Error in initializeSession:', error);
      throw error;
    }
  }, []);

  const handleUserMessage = useCallback(async (content: string) => {
    if (!sessionToken) return;

    const userMessage: ChatMessage = { 
      role: 'user',
      content 
    };
    setMessages(prev => [...prev, userMessage]);
    
    setIsTyping(true);
    try {
      const response = await sendMessage(sessionToken, content);
      let botMessageContent = response.data.content;

      // Process JSON before animation if it contains a JSON block
      const jsonMatch = botMessageContent.match(/```json\n([\s\S]*?)\n```/);
      if (jsonMatch) {
        try {
          const jsonContent = JSON.parse(jsonMatch[1]);
          if (jsonContent.isSummary || jsonContent.isOverview) {
            const formattedContent = Object.entries(jsonContent)
              .filter(([key]) => !['isSummary', 'isOverview'].includes(key))
              .map(([key, value]) => `**${key}**\n- ${value}`)
              .join('\n\n');
            
            // Replace JSON block with formatted content
            botMessageContent = botMessageContent.replace(/```json\n[\s\S]*?\n```/, formattedContent);
          }
        } catch (e) {
          console.error('Error processing JSON:', e);
        }
      }

      let currentContent = '';
      setMessages(prev => [...prev, { role: 'assistant', content: '' }]);

      // Now animate the processed content
      for (let i = 0; i < botMessageContent.length; i++) {
        currentContent += botMessageContent[i];
        setMessages(prev => {
          const newMessages = [...prev];
          newMessages[newMessages.length - 1] = { role: 'assistant', content: currentContent };
          return newMessages;
        });
        await new Promise(resolve => setTimeout(resolve, 1));
      }

      // Check both conditions for showing end chat
      const shouldEndChat = MessageValidator.shouldShowEndChat([...messages, { role: 'assistant', content: botMessageContent }]);
      if (shouldEndChat) {
        setShowEndChatOptions(true);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setNotification('Error sending message to the bot. Please try again.');
    } finally {
      setIsTyping(false);
    }
  }, [sessionToken, messages, setNotification]);

  const handleContinueChat = useCallback(() => {
    setShowEndChatOptions(false);
  }, []);

  return {
    messages,
    isTyping,
    initializeSession,
    handleUserMessage,
    showEndChatOptions,
    handleContinueChat
  };
};