import React, { useState, useEffect, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import robotIcon from '../assets/robot_icon.png';
import userIcon from '../assets/user_icon.png';
import { SRSReportApi } from '../api/srsReportApi';
import { ReportStatusHelper } from '../utils/reportStatusHelper';
import { ReportData } from '../utils/pdfGenerator';
import { SRSGenerator } from './SRSGenerator';
import { pdf } from '@react-pdf/renderer';
import CustomAlert from './CustomAlert';

interface ChatHistoryProps {
  session: {
    messages: Array<{ role: string; content: string }>;
    sessionToken: string;
  };
  onClose: () => void;
  isSideMenuOpen?: boolean;
  setIsSideMenuOpen?: (isOpen: boolean) => void;
}

const ChatHistory: React.FC<ChatHistoryProps> = ({ 
  session, 
  onClose, 
  isSideMenuOpen = false,
  setIsSideMenuOpen 
}) => {
  const [reportStatus] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  // const fetchReportStatus = useCallback(async () => {
  //   try {
  //     const response = await SRSReportApi.getReportStatus(session.sessionToken);
  //     if (response.status === 'success') {
  //       setReportStatus(response.data?.reportStatus || null);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching report status:', error);
  //   }
  // }, [session.sessionToken]);

  // useEffect(() => {
  //   fetchReportStatus();
  // }, [fetchReportStatus]);

  const handleViewReport = async () => {
    setIsLoading(true);
    try {
      const response = await SRSReportApi.generateReport(session.sessionToken);
      
      // Case 1: Success with complete report data
      if (response.status === 'success' && response.data?.step1) {
        const reportData = response.data as ReportData;
        if (reportData.step1 && reportData.step2 && reportData.step3 && 
            reportData.step4 && reportData.timestamp) {
          await handleGeneratePDF(response);
        } else {
          setAlertMessage(response.message || 'Incomplete report data received.');
          setShowAlert(true);
        }
      }
      // Case 2, 3, 4, 5: All other cases that need alert
      else {
        setAlertMessage(response.message || 'An error occurred while generating the report.');
        setShowAlert(true);
      }
    } catch (error: any) {  // Type as any to access error response
      console.error('Error handling report:', error);
      
      // Handle Bad Request (400) errors
      if (error.response?.status === 400) {
        setAlertMessage(error.response.data.message || 'Unable to generate report.');
      } else {
        setAlertMessage('An unexpected error occurred. Please try again later.');
      }
      setShowAlert(true);
    } finally {
      setIsLoading(false);
    }
  };

  const processContent = (content: string) => {
    try {
      // Check if content contains a JSON code block
      const jsonMatch = content.match(/```json\n([\s\S]*?)\n```/);
      if (jsonMatch) {
        const jsonContent = JSON.parse(jsonMatch[1]);
        
        // Check if it's a summary/overview type response
        if (jsonContent.isSummary || jsonContent.isOverview) {
          const formattedContent = Object.entries(jsonContent)
            .filter(([key]) => !['isSummary', 'isOverview'].includes(key))
            .map(([key, value]) => `**${key}**\n- ${value}`)
            .join('\n\n');
            
          // Replace the JSON block with formatted content
          return content.replace(/```json\n[\s\S]*?\n```/, formattedContent);
        }
      }
      
      return content;
    } catch (e) {
      return content;
    }
  };

  async function handleGeneratePDF(response: any) {
    try {
      const reportData = response.data as ReportData;
      if (reportData.step1 && reportData.step2 && reportData.step3 && 
          reportData.step4 && reportData.timestamp && reportData.name && reportData.email) {
        
        // Generate the PDF document
        const PDFDocument = await SRSGenerator.generateSRSPDF(reportData);
        
        // Option 1: Open in new window/tab
        const blob = await pdf(<PDFDocument />).toBlob();
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
        
        // Option 2: Direct download
        // const fileName = `SRS_Report_${new Date().toISOString().split('T')[0]}.pdf`;
        // const aLink = document.createElement('a');
        // aLink.href = url;
        // aLink.download = fileName;
        // aLink.click();
        // URL.revokeObjectURL(url);
        
        // Option 3: Show in viewer (requires adding viewer to your UI)
        // setCurrentPDF(<PDFViewer style={{ width: '100%', height: '800px' }}>
        //   <PDFDocument />
        // </PDFViewer>);
        
      } else {
        throw new Error('Incomplete report data');
      }
    } catch (error) {
      console.error('Error generating PDF:', error);
      // Handle error appropriately
    }
  }

  // Handle alert close and sidebar
  const handleAlertClose = () => {
    setShowAlert(false);
    onClose(); // Close sidebar after alert is closed
  };

  return (
    <>
      <div className="chat-history-overlay">
        <div className="chat-history-sidebar">
          <div className="chat-history-header">
            <h2>Chat History</h2>
            <button className="close-btn" onClick={onClose}>&times;</button>
          </div>

          <div className="chat-history-messages">
            {session.messages.slice(1).map((message, index) => (
              <div 
                key={index} 
                className={`message-wrapper ${message.role}`}
              >
                <div className="message-avatar">
                  <img 
                    src={message.role === 'assistant' ? robotIcon : userIcon} 
                    alt={message.role} 
                    className="avatar-icon" 
                  />
                </div>
                <div className="message-content">
                  <ReactMarkdown>{processContent(message.content)}</ReactMarkdown>
                </div>
              </div>
            ))}
          </div>

          {session.messages.length > 6 && (
            <div className="sticky-report-button">
              <button 
                className="view-report-btn"
                onClick={handleViewReport}
                disabled={!ReportStatusHelper.isButtonEnabled(reportStatus) || isLoading}
              >
                {ReportStatusHelper.getButtonText(reportStatus)}
              </button>
            </div>
          )}
        </div>
      </div>

      <CustomAlert 
        isOpen={showAlert}
        onClose={handleAlertClose}
        message={alertMessage}
      />
    </>
  );
};

export default ChatHistory;