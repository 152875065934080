// src/pages/ChatPage.tsx
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowUp } from 'react-icons/fa';
import ChatMessage from '../components/ChatMessage';
import { useChat } from '../hooks/useChat';
import { checkSessionHistory, closeChat, getChatSummary } from '../api/chatApi';
import SessionModal from '../components/SessionModal';
import ConfirmationModal from '../components/ConfirmationModal';
import logoIcon from '../assets/monstarx_logo_w.png';
import robotIcon from '../assets/robot_icon.png';
import '../styles/style.css';
import SideMenu from '../components/SideMenu';
import Notification from '../components/Notification';

const ChatPage: React.FC = () => {
  const navigate = useNavigate();
  const [inputMessage, setInputMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const initRef = React.useRef(false);
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [chatSummary, setChatSummary] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notification, setNotification] = useState<string | null>(null);
  const MAX_CHARS = 1000;
  const [charCount, setCharCount] = useState(0);

  const { 
    messages, 
    isTyping, 
    initializeSession, 
    handleUserMessage,
    showEndChatOptions,
    handleContinueChat
  } = useChat(setNotification);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, isTyping]);

  useEffect(() => {
    const checkExistingSession = async () => {
      if (!initRef.current) {
        initRef.current = true;
        
        try {
        //  console.log('ChatPage: Starting session check');
          const continuingFromIntro = localStorage.getItem('continuing_from_intro');
       //   console.log('ChatPage: continuing_from_intro flag:', continuingFromIntro);
          
          if (continuingFromIntro) {
         //   console.log('ChatPage: Continuing from intro, loading existing session');
            localStorage.removeItem('continuing_from_intro');
            await initializeSession();
            setLoading(false);
            return;
          }

          const existingToken = localStorage.getItem('monstarx_session_token');
          if (existingToken) {
            const history = await checkSessionHistory(existingToken);
            if (history.data.messages.length > 0) {
              setShowModal(true);
              setLoading(false);
              return;
            }
          }
          
          await initializeSession();
        } catch (error) {
          console.error('ChatPage: Error in session check:', error);
          localStorage.removeItem('monstarx_session_token');
          await initializeSession();
        } finally {
          setLoading(false);
        }
      }
    };

    checkExistingSession();
  }, [initializeSession]);

  const handleContinueSession = async () => {
    setLoading(true);
    try {
      localStorage.setItem('continue_previous_session', 'true');
      await initializeSession();
    } catch (error) {
      console.error('Error continuing session:', error);
    } finally {
      setShowModal(false);
      setLoading(false);
    }
  };

  const handleStartNew = async () => {
    setLoading(true);
    try {
      localStorage.removeItem('monstarx_session_token');
      localStorage.removeItem('continue_previous_session');
      await initializeSession();
    } catch (error) {
      console.error('Error starting new session:', error);
    } finally {
      setShowModal(false);
      setLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() === '' || loading || isTyping) return;

    const messageToSend = inputMessage;
    setInputMessage('');
    setCharCount(0); 
    await handleUserMessage(messageToSend);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevent default behavior of Enter key
      handleSendMessage(); // Call handleSendMessage to send the message
    }
  };

  const handleSubmitChat = async () => {
    const sessionToken = localStorage.getItem('monstarx_session_token');
    if (!sessionToken) return;

    setShowSideMenu(true);
    setLoading(true);

    try {
        const data = await getChatSummary(sessionToken);

        if (data.status === 'success') {
            let appOverview = data.appOverview;

            if (typeof appOverview === 'string') {
                appOverview = [appOverview];
            }

            if (Array.isArray(appOverview)) {
                setChatSummary(appOverview);
            } else {
                console.error('appOverview is not an array:', appOverview);
                setChatSummary(['Error: Summary format is incorrect.']);
            }
        } else {
            console.error('Error retrieving chat summary:', data.message);
            setChatSummary([`Error: ${data.message}`]);
        }
    } catch (error) {
        console.error('Error retrieving chat summary:', error);
        setChatSummary(['Error: Unable to fetch summary. Please try again.']);
    } finally {
        setLoading(false);
    }
  };

  const handleCloseSideMenu = () => {
    setShowSideMenu(false);
  };

  const handleSubmitClose = async () => {
   // console.log('handleSubmitClose');
    const sessionToken = localStorage.getItem('monstarx_session_token');
    if (!sessionToken) return;

    setIsSubmitting(true);

    try {
      await closeChat(sessionToken);
      localStorage.removeItem('monstarx_session_token');
      localStorage.removeItem('continue_previous_session');
      localStorage.removeItem('prevent_modal_show');
      navigate('/'); // Navigate back to IntroPage
    } catch (error) {
      console.error('Error closing chat:', error);
      setNotification('Bot is busy now, try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNotificationClose = () => {
    setNotification(null);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;
    if (text.length <= MAX_CHARS) {
      setInputMessage(text);
      setCharCount(text.length);
    }
  };

  const handleCloseChatAndNavigate = async () => {
    setIsSubmitting(true);
    try {
      const sessionToken = localStorage.getItem('monstarx_session_token');
      if (sessionToken) {
        await closeChat(sessionToken);
        localStorage.removeItem('monstarx_session_token');
        localStorage.removeItem('continue_previous_session');
      }
      navigate('/');
    } catch (error) {
      console.error('Error closing chat:', error);
      setNotification('Failed to close chat. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="chat-page">
      <div className="chat-header">
        <div className="header-left">
          <div className="header-robot-icon">
            <img src={robotIcon} alt="Robot" className="robot-icon" />
          </div>
          <img src={logoIcon} alt="MonstarX Logo" className="chat-logo" />
        </div>
        {/* Comment out or remove the Summarize button 
        <button 
          className="submit-button" 
          onClick={handleSubmitChat}
          disabled={loading || isTyping}
        >
          Summarize
        </button>
        */}
      </div>

      <div className="chat-main-container">
        <div className="chat-container">
          {messages.map((message, index) => (
            <ChatMessage 
              key={index} 
              role={message.role} 
              content={message.content} 
            />
          ))}
          {isTyping && (
            <div className="typing-indicator">
              MonstarX Bot is typing...
            </div>
          )}
          <div ref={chatEndRef} />
        </div>
        <div className="chat-input-container">
          {showSideMenu ? null : (
            loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : showEndChatOptions ? (
              <div className="end-chat-options">
                <button 
                  className="submit-button end-chat-btn"
                  onClick={handleCloseChatAndNavigate}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Closing...' : 'End Chat'}
                </button>
                <button 
                  className="submit-button continue-chat-btn"
                  onClick={handleContinueChat}
                >
                  Continue Chat
                </button>
              </div>
            ) : (
              <div className="input-wrapper">
                <textarea
                  className="chat-input"
                  value={inputMessage}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  placeholder={`Type your message here...`}
                  rows={1}
                  maxLength={MAX_CHARS}
                />
                <div className={`char-counter ${
                  charCount >= MAX_CHARS ? 'limit' : 
                  charCount >= MAX_CHARS * 0.9 ? 'warning' : ''
                }`}>
                  {charCount}/{MAX_CHARS} characters
                </div>
                <button 
                  className="send-icon-button" 
                  onClick={handleSendMessage} 
                  disabled={loading || isTyping || !inputMessage.trim()}
                >
                  <FaArrowUp />
                </button>
              </div>
            )
          )}
        </div>
      </div>

      {showModal && (
        <SessionModal
          onContinue={handleContinueSession}
          onStartNew={handleStartNew}
          showCloseButton={false}
        />
      )}

      {showSubmitModal && (
        <ConfirmationModal
          onConfirm={handleSubmitChat}
          onCancel={() => setShowSubmitModal(false)}
          title="Are you sure you want to submit?"
          confirmText="Yes"
          cancelText="No"
        />
      )}

      {showSideMenu && (
        <SideMenu
          isOpen={showSideMenu}
          onClose={handleCloseSideMenu}
          onSubmitClose={handleSubmitClose}
          chatSummary={chatSummary}
          loading={loading}
          isSubmitting={isSubmitting}
        />
      )}

      {notification && (
        <Notification message={notification} onClose={handleNotificationClose} />
      )}
    </div>
  );
};

export default ChatPage;