import React from 'react';
import '../styles/SessionModal.css'; // We'll reuse some of these styles

interface CustomAlertProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
}

const CustomAlert: React.FC<CustomAlertProps> = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <div className="session-modal-overlay" style={{ zIndex: 2000 }}>
      <div className="session-modal-popup" style={{ maxWidth: '400px' }}>
        <button className="session-modal-close" onClick={onClose}>
          ×
        </button>
        
        <div className="session-modal-icon">
          <i className="fas fa-info-circle"></i>
        </div>

        <div className="session-modal-header">
          Info
        </div>

        <div className="session-modal-message">
          {message}
        </div>

        <div className="session-modal-buttons">
          <button 
            className="session-modal-button primary"
            onClick={onClose}
            style={{ minWidth: '100px' }}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomAlert;
