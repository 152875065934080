// src/components/ChatMessage.tsx
import React from 'react';
import robotIcon from '../assets/robot_icon.png';
import { MessageRole } from '../models/ChatMessage';
import ReactMarkdown from 'react-markdown';

interface ChatMessageProps {
  role: MessageRole;
  content: string;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ role, content }) => {
  const displayRole = role === 'You' ? 'user' : role;
  
  const processContent = (content: string) => {
    try {
      // Wait for complete JSON block before processing
      const jsonBlockComplete = content.includes('```json') && content.includes('```\n');
      if (!jsonBlockComplete) {
        return content;
      }

      const jsonMatch = content.match(/```json\n([\s\S]*?)\n```/);
      if (jsonMatch) {
        // Verify JSON block is complete and valid
        const jsonString = jsonMatch[1].trim();
        if (!jsonString.endsWith('}')) {
          return content;
        }

        const jsonContent = JSON.parse(jsonString);
        
        if (jsonContent.isSummary || jsonContent.isOverview) {
          const formattedContent = Object.entries(jsonContent)
            .filter(([key]) => !['isSummary', 'isOverview'].includes(key))
            .map(([key, value]) => `**${key}**\n- ${value}`)
            .join('\n\n');
            
          return content.replace(/```json\n[\s\S]*?\n```/, formattedContent);
        }
      }
      
      return content;
    } catch (e) {
      return content;
    }
  };

  return (
    <div className={`message-wrapper ${displayRole}`}>
      {displayRole === 'assistant' && (
        <div className="message-avatar">
          <img src={robotIcon} alt="Bot" className="avatar-icon" />
        </div>
      )}
      <div className="message-content">
        <ReactMarkdown>{processContent(content)}</ReactMarkdown>
      </div>
    </div>
  );
};

export default ChatMessage;