export enum ReportStatus {
  INITIATED = 0,
  FAILED = 1,
  COMPLETED = 2
}

export class ReportStatusHelper {
  static isButtonEnabled(reportStatus: number | null): boolean {
    if (reportStatus === null) return true;
    if (reportStatus === ReportStatus.INITIATED) return false;
    return true;
  }

  static getButtonText(reportStatus: number | null): string {
    if (reportStatus === ReportStatus.INITIATED) return 'Generating...';
    return 'View Report';
  }
} 